import React, { FC, Suspense } from "react";
import { checkIsObjectEmpty } from "../../../../../utils/common/dataTypes/object";
import { IPostPollProps } from "./Poll.interfaces";

const Poll = React.lazy(() => import("./Poll"));

export const LazyLoadedPoll: FC<
  IPostPollProps & { onClickVotes: () => void }
> = (props) => {
  const {
    showPoll,
    pollData,
    votingDisabled,
    onClickVotes,
    callCastVote,
    errorLogger,
    notifyError,
  } = props;

  if (!showPoll || !pollData || checkIsObjectEmpty(pollData)) return null;

  return (
    <Suspense fallback={null}>
      <Poll
        data={pollData}
        votingDisabled={votingDisabled}
        errorLogger={errorLogger}
        notifyError={notifyError}
        callCastVote={callCastVote}
        onClickVotes={onClickVotes}
      />
    </Suspense>
  );
};
